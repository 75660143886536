import React, { useState } from 'react';
import { motion } from 'framer-motion';
import SecondPageFoot from './secondpagefoot.js'

const particles = Array.from({ length: 80 }); // Adjust particle count as needed

const AnimatedBackgroundWithContent = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleJoinWaitlist = async () => {
    if (!email) {
      setMessage('Please enter a valid email address.');
      setIsError(true);
      return;
    }
    console.log(email);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setMessage('Please enter a valid email format.');
      setIsError(true);
      return;
    }

    setIsClicked(true);

    try {
      const response = await fetch('https://fyntl.sangrahinnovations.com/waitlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      console.log(data);
      if (response.ok) {
        setMessage('Thank you! You have been added to the waitlist.');
        setIsError(false);
        setEmail('');
      } else {
        setMessage(data.error || 'An error occurred. Please try again.');
        setIsError(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Failed to connect to the server.');
      setIsError(true);
    } finally {
      setTimeout(() => {
        setIsClicked(false);
      }, 300);
    }
  };
  return (
    <div className="relative min-h-screen overflow-hidden bg-white">
      {/* Particles */}
      {particles.map((_, index) => (
        <motion.div
          key={index}
          className="absolute rounded-full"
          style={{
            position: 'fixed',
            overflow: 'visible',
            width: `${Math.random() * 10 + 5}px`,
            height: `${Math.random() * 10 + 5}px`,
            top: `${Math.random() * 100}vh`,
            left: `${Math.random() * 100}vw`,
            backgroundColor: `rgba(173, 216, 230, ${Math.random() * 0.7 + 0.3})`,
          }}
          animate={{
            y: ['-10vh', '110vh'],
            opacity: [0.3, 0.8, 0.3],
          }}
          transition={{
            duration: Math.random() * 8 + 5,
            repeat: Infinity,
            ease: 'easeInOut',
          }}
        />
      ))}

      {/* Content */}
      <div className="relative z-10 flex flex-col items-center justify-center min-h-screen text-center px-4 md:px-6">
        <h1 className="absolute top-4 left-4 md:left-6 text-gray-800 text-base md:text-lg font-bold">FYNTL AI</h1>

        <div className="mb-4 md:mb-6 p-2 rounded border border-dotted border-blue-500 text-xs md:text-sm text-blue-600">
          Unlock Future of Business with Fyntl AI
        </div>

        <motion.h2
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, type: 'spring' }}
          className="font-bold text-gray-900 mb-4 md:mb-6 leading-snug text-4xl md:text-[72px]"
        >
          AI powered <br />
          Business OS for SME's
        </motion.h2>
        
        <p className="text-gray-600 max-w-sm md:max-w-lg mb-6 md:mb-8 text-xs md:text-sm lg:text-base leading-relaxed">
          A cutting-edge Generative AI-powered Operating System for MSMEs, integrating an extensive AI Marketplace with plug-and-play solutions, automated workflows, predictive analytics, and personalized business insights to propel growth, efficiency, and innovation.
        </p>
        <div className="flex flex-col md:flex-row items-center max-w-sm md:max-w-lg w-full mt-4 border border-gray-300 rounded-xl overflow-hidden shadow-lg space-y-2 md:space-y-0 md:space-x-2 p-2">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="w-full md:flex-grow p-2 md:p-3 text-xs md:text-base focus:outline-none text-gray-800 rounded-lg md:rounded-none md:rounded-l-lg border md:border-0"
          />
          <motion.button 
            onClick={handleJoinWaitlist}
            style={{
              color: 'white',
              background: '#040499',
              border: '3px solid #03036a',
              marginRight: '0.7%',
              padding: '0.2rem 1rem',
              whiteSpace: 'nowrap', // Set width to auto for desktop
            }}
            className="hidden md:inline-block px-4 md:px-6 py-2 md:py-3 text-sm md:text-lg font-semibold rounded-xl md:rounded-r-xl transition-all duration-200 focus:outline-none"
            initial={{ scale: 1 }}
            animate={{ scale: isClicked ? 0.80 : 1 }}
            transition={{ type: 'spring', stiffness: 500, damping: 60, mass: 1 }}
            whileTap={{ scale: 0.95 }}
            whileHover={{ scale: 1.01 }}
          >
            Join Waitlist
          </motion.button>

          {/* Separate button for mobile view */}
          <motion.button
            onClick={handleJoinWaitlist}
            style={{
              color: 'white',
              background: '#040499',
              border: '3px solid #03036a',
              width: '100%', // Full width for mobile
            }}
            className="md:hidden w-full px-6 py-0.5 text-lg font-semibold transition-all duration-200 focus:outline-none rounded-lg"
            initial={{ scale: 1 }}
            animate={{ scale: isClicked ? 0.80 : 1 }}
            transition={{ type: 'spring', stiffness: 500, damping: 60, mass: 1 }}
            whileTap={{ scale: 0.95 }}
            whileHover={{ scale: 1.01 }}
          >
            Join Waitlist
          </motion.button>
        </div>




        {message && (
          <div
            className="mt-4 px-6 py-3 max-w-lg w-full text-sm md:text-base font-semibold rounded-md"
            style={{
              backgroundColor: isError ? '#f44336' : '#4CAF50',
              border: isError ? '1px solid #D32F2F' : '1px solid #388E3C',
              color: 'white',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              transition: 'opacity 0.5s ease-in-out',
            }}
          >
            {message}
          </div>
        )}
      </div>

      {/* SecondPageFoot Component */}
      <div className="mt-8 md:mt-12">
        <SecondPageFoot />
      </div>
    </div>
  );

};

export default AnimatedBackgroundWithContent;
