import React from 'react';
import { motion } from 'framer-motion';

const SecondPageFoot = () => {
  return (
    <div className="flex flex-col items-center">
    <motion.div
    initial={{ opacity: 0, y: 20, scale: 0.95 }}
    animate={{ opacity: 1, y: 0, scale: 1 }}
    transition={{ duration: 1, ease: 'easeOut', delay: 0.2 }}
    whileHover={{scale:1.05}}
    style={{
      maxWidth: '70%',
      height: '600px', // Reduced height for mobile
      margin: '24px auto',
      backgroundColor: '#f5f5f5',
      borderRadius: '10px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
    }}
    className="flex items-center justify-center text-gray-700 w-full sm:w-[90%]"
  >
    <iframe
      src="https://www.youtube.com/embed/P0IpabtSe6g"
      title="YouTube video"
      className="w-full h-full object-cover"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  </motion.div>
  

      <div className="bg-gray-100 mt-6 p-6 md:p-12 lg:p-16 space-y-6 w-full">
        {/* One Interface Card */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false, amount: "some" }}
          transition={{ duration: 1.1, ease: 'easeOut' }}
          className="flex flex-col md:flex-row items-center max-w-6xl mx-auto space-y-4 md:space-y-0 md:space-x-4"
          whileHover={{ scale: 1.05 }}
        >
          <div className="w-full md:w-1/2 h-48 md:h-80 flex-shrink-0 rounded-md">
            <img src="/group_43.png" alt="Feature 1" className="w-full h-full object-cover rounded-xl" />
          </div>
          <div className="text-center md:text-left px-4">
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-[50px] font-bold leading-tight">
              One Interface to create <br className="hidden md:block" /> Thousands of AI agents
            </h1>
            <p className="text-gray-500 text-base md:text-lg mt-4 max-w-[40ch]">
              Imagine waking up in the morning and AI has already followed up with all vendors, proposing you with a simple Yes/No decision that you can resolve with a click.
            </p>
          </div>

        </motion.div>

        {/* Global Marketplace Card */}
        <motion.div 
          className="flex flex-col md:flex-row items-center max-w-6xl mx-auto space-y-4 md:space-y-0 md:space-x-4"
          style={{marginTop:'2%'}}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.9, ease: 'easeOut'}}
        >
          <div className="text-center md:text-left px-4">
            <h2 className="text-2xl md:text-4xl font-bold leading-tight">
              Global Marketplace
            </h2>
            <p className="text-gray-500 text-base md:text-lg mt-4">
            Our AI marketplace brings massive growth opportunities to the table. It automatically tracks inventory and finds the best deal for you or shares where you can sell your product globally. AI manages cross-border formalities, payments, language barriers, etc., saving you thousands of hours with massive business opportunities globally.
            </p>
          </div>
          <div className="w-full md:w-1/2 h-48 md:h-80 flex-shrink-0 rounded-md">
            <img
              src="/world_map.png"
              alt="Global Marketplace"
              className="w-full h-full object-cover rounded-md"
            />
          </div>
        </motion.div>
      </div>

      {/* Next Feature Card Section */}
      <div 
        className="flex flex-col md:flex-row items-start p-4 space-y-4 md:space-y-0 md:space-x-4 w-full max-w-6xl"
        style={{marginTop:'7%'}}
      >
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.9, ease: 'easeOut' }}
          className="w-full md:w-1/2"
          style={{marginRight:'3%'}}
          whileHover={{ scale: 1.05 }}
        >
          <div className="bg-gray-200 h-40 md:h-60 rounded-2xl overflow-hidden mb-4 p-4">
            <img 
              src="/group_33084.png" 
              alt="Fyntl AI engine can fetch insights from data" 
              className="w-full h-full object-contain"
            />
          </div>
          <h2 className="text-lg md:text-2xl font-semibold mb-2">Fyntl AI engine can fetch insights from data</h2>
          <p className="text-gray-500 text-sm md:text-lg mt-2">
          Your business data is sitting on ERP, mails, spreadsheets, and sometimes on WhatsApp, that is unused. Fyntl AI engine can fetch you live reports, insights, feedback. This will not only help you make data-driven decisions but give you an extra edge over competitors.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.9, ease: 'easeOut' }}
          className="w-full md:w-1/2"
          style={{marginLeft:'3%'}}
          whileHover={{ scale: 1.05 }}
        >
          <div className="bg-gray-200 h-40 md:h-60 rounded-2xl overflow-hidden mb-4">
            <img 
              src="/group_33083.png" 
              alt="One Single prompt for all" 
              className="w-full h-full object-contain"
            />
          </div>
          <h2 className="text-lg md:text-2xl font-semibold mb-2">One Single prompt for all!</h2>
          <p className="text-gray-500 text-sm md:text-lg mt-2">
          Our AI technology is developed so that you can control and manage your business through messages. It’s simple and easy. With support for 150+ languages and reach in 100+ countries, it’s the right time to adopt AI for your business.
          </p>
        </motion.div>
      </div>

      {/* Footer */}
      <div className="w-full bg-[#030373] py-4 md:py-6 flex flex-col md:flex-row justify-between items-center text-white px-4 md:px-8 space-y-2 md:space-y-0"
        style={{marginTop:'5%'}}
      >
        <span>© FYNTL Inc. 2024</span>
        <div className="flex space-x-4">
          <motion.a href="https://www.linkedin.com/company/fyntl-ai/" target="_blank" rel="noopener noreferrer"
              whileHover={{ scale: 1.05 }}
              className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-blue-500 text-white">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 448 512">
              <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
            </svg>
          </motion.a>
          <motion.a href="mailto:fyntl.ai@gmail.com" target="_blank" rel="noopener noreferrer"
              whileHover={{ scale: 1.05 }}
              className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-blue-500 text-white">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
              <path d="M12 13.5l-8-5h16l-8 5zm-10-5.5v10h20v-10l-10 6-10-6z" />
            </svg>
          </motion.a>
        </div>
      </div>
    </div>
  );
};

export default SecondPageFoot;
