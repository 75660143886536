import React, { useEffect, useState } from 'react';
import LandingPage from './components/landing';
import BroucherForm from './components/broucher';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { initializeAnalytics, trackPageView } from './components/analytics';

const AnalyticsWrapper = ({ isConsentGiven }) => {
  const location = useLocation();

  useEffect(() => {
    if (isConsentGiven) {
      trackPageView(location.pathname); // Track page views on route change
    }
  }, [location, isConsentGiven]);

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/broucher" element={<BroucherForm />} />
    </Routes>
  );
};

const App = () => {
  const [isConsentGiven, setIsConsentGiven] = useState(
    localStorage.getItem('analytics-consent') === 'true'
  );

  useEffect(() => {
    if (isConsentGiven) {
      // Inject the Google Analytics script tags
      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = "https://www.googletagmanager.com/gtag/js?id=G-HRJ13F4P2J";
      document.head.appendChild(script1);

      const script2 = document.createElement('script');
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-HRJ13F4P2J');
      `;
      document.head.appendChild(script2);
      
      initializeAnalytics(); // Initialize other custom analytics if necessary
    }
  }, [isConsentGiven]);

  return (
    <Router>
      <div>
        {/* Your app content */}
        <AnalyticsWrapper isConsentGiven={isConsentGiven} />
      </div>
    </Router>
  );
};

export default App;
