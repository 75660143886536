import React, { useState } from 'react';
import { motion } from 'framer-motion';

const BroucherForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const handleDownload = async () => {
        // Example API call - replace with your actual API endpoint
        const response = await fetch('https://fyntl.sangrahinnovations.com/broucher', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, email }),
        });
        if (response.ok) {
            //console.log(await response.json());
            const { url } = await response.json();
            window.open(url, '_blank');
        } else {
            console.error('Download failed:', response.statusText);
        }
    };

    // Generate an array of particles
    const particles = Array.from({ length: 20 }); // Change the number for more/less particles

    return (
        <div className="relative min-h-screen overflow-hidden bg-gray-900"> {/* Changed background to dark gray */}
            {/* Particles */}
            {particles.map((_, index) => (
                <motion.div
                    key={index}
                    className="absolute rounded-full"
                    style={{
                        position: 'fixed',
                        overflow: 'visible',
                        width: `${Math.random() * 10 + 5}px`,
                        height: `${Math.random() * 10 + 5}px`,
                        top: `${Math.random() * 100}vh`,
                        left: `${Math.random() * 100}vw`,
                        backgroundColor: `rgba(173, 216, 230, ${Math.random() * 0.7 + 0.3})`,
                    }}
                    animate={{
                        y: ['-10vh', '110vh'],
                        opacity: [0.3, 0.8, 0.3],
                    }}
                    transition={{
                        duration: Math.random() * 8 + 5,
                        repeat: Infinity,
                        ease: 'easeInOut',
                    }}
                />
            ))}

            {/* Form Section */}
            <div className="flex items-center justify-center h-screen">
                <div className="container mx-auto max-w-sm bg-white rounded-lg shadow-lg overflow-hidden z-10"> {/* Reduced max-w-md to max-w-sm */}
                    <motion.div
                        className="p-6"
                        initial={{ opacity: 0, y: 20 }} // Initial state
                        animate={{ opacity: 1, y: 0 }} // Animate to visible
                        exit={{ opacity: 0, y: 20 }} // Exit state
                        transition={{ duration: 0.5 }} // Transition duration
                    >
                        <h2 className="text-2xl font-semibold text-gray-800 text-center mb-4">Download Broucher</h2>
                        <form onSubmit={(e) => { e.preventDefault(); handleDownload(); }}>
                            <div className="input-field p-4">
                                <motion.input
                                    type="text"
                                    placeholder="Name"
                                    required
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="w-full p-2 border-b-2 border-gray-300 focus:outline-none focus:border-blue-600 transition duration-300 ease-in-out rounded-md"
                                    whileFocus={{ scale: 1.05, boxShadow: '0 0 5px rgba(0, 0, 255, 0.5)' }}
                                    initial={{ boxShadow: 'none' }}
                                />
                            </div>
                            <div className="input-field p-4">
                                <motion.input
                                    type="email"
                                    placeholder="Email"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="w-full p-2 border-b-2 border-gray-300 focus:outline-none focus:border-blue-600 transition duration-300 ease-in-out rounded-md"
                                    whileFocus={{ scale: 1.05, boxShadow: '0 0 5px rgba(0, 0, 255, 0.5)' }}
                                    initial={{ boxShadow: 'none' }}
                                />
                            </div>
                            <div className="input-field button p-4">
                                <motion.button
                                    type="submit"
                                    className="w-full p-2 bg-blue-600 text-white rounded hover:bg-blue-700 cursor-pointer transition rounded-lg"
                                    animate={{ opacity: 1, y: 0 }}
                                    whileFocus={{ scale: 1.05, boxShadow: '0 0 5px rgba(0, 0, 255, 0.5)' }}
                                    transition={{ type: 'spring', stiffness: 500, damping: 60, mass: 1 }}
                                    whileTap={{ scale: 0.95 }}
                                    whileHover={{ scale: 1.01 }}
                                    style={{
                                        color: 'white',
                                        background: '#040499',
                                    }}
                                >
                                    Download
                                </motion.button>
                            </div>
                        </form>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default BroucherForm; 
